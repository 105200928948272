import request from '@/utils/request'

/**
 * 财务 获取待对账列表接口
 * @returns {*}
 */
export function reconciliations (params) {
  return request({
    url: 'finance/reconciliations',
    method: 'get',
    params
  })
}

/**
 * 财务 获取待对账详情接口
 * @returns {*}
 */
export function Detailsreconciliations (id,params) {
  return request({
    url: `finance/reconciliations/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 修改合同价接口
 * @returns {*}
 */
export function edit_contract_price (id,data) {
  return request({
    url: `finance/reconciliation/edit_contract_price/${id}`,
    method: 'post',
    data
  })
}

/**
 * 财务 运力待对账 修改合同价接口
 * @returns {*}
 */
export function capacityedit_contract_price (id,data) {
  return request({
    url: `finance/capacity_reconciliation/edit_contract_price/${id}`,
    method: 'post',
    data
  })
}

/**
 * 财务 获取待对账详情接口
 * @returns {*}
 */
export function reconciliationsstatistics (id,params) {
  return request({
    url: `finance/reconciliation/statistics/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 运力获取待对账详情接口
 * @returns {*}
 */
export function statistics (id,params) {
  return request({
    url: `finance/capacity_reconciliation/statistics/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 获取待对账详情开票资料
 * @returns {*}
 */
export function get_invoice_information (params) {
  return request({
    url: 'finance/reconciliation/get_invoice_information',
    method: 'get',
    params
  })
}

/**
 * 财务 运力获取待对账详情开票资料
 * @returns {*}
 */
export function capacity_reconciliationget_invoice_information (id,params) {
  return request({
    url: `finance/capacity_reconciliation/get_invoice_information/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 获取收款资料下拉列表接口
 * @returns {*}
 */
export function get_collection_profile (params) {
  return request({
    url: 'finance/reconciliation/get_collection_profile',
    method: 'get',
    params
  })
}
/**
 * 财务 对账发起成功结算待对账接口
 * @returns {*}
 */
export function saveremittance (id,params) {
  return request({
    url: `finance/reconciliation/order_remittance/${id}`,
    method: 'post',
    params
  })
}

/**
 * 财务 对账发起确认接口
 * @returns {*}
 */
export function affirm (id,params) {
  return request({
    url: `finance/reconciliation/reconciliation_initiate/${id}`,
    method: 'post',
    params
  })
}

/**
 * 财务 结算待对账完成对账操作
 * @returns {*}
 */
export function wanchengremittance (id,params) {
  return request({
    url: `finance/reconciliation/remittance/${id}`,
    method: 'post',
    params
  })
}
/**
 * 财务 运力 结算待对账完成对账操作
 * @returns {*}
 */
export function capacity_reconciliationremittance (id,params) {
  return request({
    url: `finance/capacity_reconciliation/remittance/${id}`,
    method: 'post',
    params
  })
}


/**
 * 财务 获取详情页面中列表数据
 * @returns {*}
 */
export function order_list (id,params) {
  return request({
    url: `finance/reconciliation/order_list/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 对账订单日志接口
 * @returns {*}
 */
export function reconciliation_log (id,params) {
  return request({
    url: `finance/reconciliation/reconciliation_log/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 运力对账订单日志接口
 * @returns {*}
 */
export function capacity_reconciliationreconciliation_log (id,params) {
  return request({
    url: `finance/capacity_reconciliation/reconciliation_log/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 详情 对账订单确认操作
 * @returns {*}
 */
export function reconciliationaffirm (id,params) {
  return request({
    url: `finance/reconciliation/order_affirm/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 详情 对账订单撤销操作
 * @returns {*}
 */
export function reconciliationrevocation (id,params) {
  return request({
    url: `finance/reconciliation/order_revocation/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 详情 对账订单不符操作
 * @returns {*}
 */
export function reconciliationreject (id,data) {
  return request({
    url: `finance/reconciliation/order_reject/${id}`,
    method: 'post',
    data
  })
}


/**
 * 财务 待发起对账 发起对账接口
 * @returns {*}
 */
export function savereconciliations (data) {
  return request({
    url: `finance/reconciliations`,
    method: 'post',
    data
  })
}
/**
 * 财务 待发起对账 完成撤销
 * @returns {*}
 */
export function revocation (id,params) {
  return request({
    url: `finance/reconciliation/revocation/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 获取结算待对账列表接口
 * @returns {*}
 */
export function delay_reconciliation (params) {
  return request({
    url: 'finance/delay_reconciliation',
    method: 'get',
    params
  })
}


/**
 * 财务 对账头部统计
 * @returns {*}
 */
export function reconciliation_statistics (id,params) {
  return request({
    url: `finance/reconciliation/reconciliation_statistics/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 获取结算待开票列表
 * @returns {*}
 */
export function delay_invoice (params) {
  return request({
    url: 'finance/delay_invoice',
    method: 'get',
    params
  })
}
/**
 * 财务 待开票列表 上传发票详情
 * @returns {*}
 */
export function getget_invoice_info (id,params) {
  return request({
    url: `finance/reconciliation/get_invoice_info/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 运力 待开票列表 上传发票详情
 * @returns {*}
 */
export function capacity_reconciliationget_invoice_info (id,params) {
  return request({
    url: `finance/capacity_reconciliation/get_invoice_info/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 待开票列表 上传发票
 * @returns {*}
 */
export function upload_invoice (id,data) {
  return request({
    url: `finance/reconciliation/upload_invoice/${id}`,
    method: 'post',
    data
  })
}

/**
 * 财务 运力 待开票列表 上传发票
 * @returns {*}
 */
export function capacity_reconciliationupload_invoice (id,data) {
  return request({
    url: `finance/capacity_reconciliation/upload_invoice/${id}`,
    method: 'post',
    data
  })
}


/**
 * 财务 获取结算待汇款列表
 * @returns {*}
 */
export function delay_remittance (params) {
  return request({
    url: 'finance/delay_remittance',
    method: 'get',
    params
  })
}
/**
 * 财务 待汇款列表 获取收款信息接口
 * @returns {*}
 */
export function get_payment_proof (id,params) {
  return request({
    url: `finance/reconciliation/get_payment_proof/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 运力 待汇款列表 获取收款信息接口
 * @returns {*}
 */
export function capacity_reconciliationget_payment_proof (id,params) {
  return request({
    url: `finance/capacity_reconciliation/get_payment_proof/${id}`,
    method: 'get',
    params
  })
}


/**
 * 财务 待开票列表 上传凭证
 * @returns {*}
 */
export function upload_payment (id,data) {
  return request({
    url: `finance/reconciliation/upload_payment/${id}`,
    method: 'post',
    data
  })
}
/**
 * 财务 运力待开票列表 上传凭证
 * @returns {*}
 */
export function capacity_reconciliationupload_payment (id,data) {
  return request({
    url: `finance/capacity_reconciliation/upload_payment/${id}`,
    method: 'post',
    data
  })
}


/**
 * 财务 获取结算待收款列表
 * @returns {*}
 */
export function delay_proceeds (params) {
  return request({
    url: 'finance/delay_proceeds',
    method: 'get',
    params
  })
}
/**
 * 财务 待汇款列表 对账核销接口
 * @returns {*}
 */
export function verification (id,data) {
  return request({
    url: `finance/reconciliation/verification/${id}`,
    method: 'get',
    data
  })
}

/**
 * 财务 获取结算已收款列表
 * @returns {*}
 */
export function getdelay_proceeds (params) {
  return request({
    url: 'finance/already_proceeds',
    method: 'get',
    params
  })
}


/**
 * 财务 获取收支明细列表接口
 * @returns {*}
 */
export function moneylis (params) {
  return request({
    url: 'finance/money/list',
    method: 'get',
    params
  })
}
/**
 * 财务 获取收支明细统计接口
 * @returns {*}
 */
export function moneystatistics (params) {
  return request({
    url: 'finance/money/statistics',
    method: 'get',
    params
  })
}


/**
 * 财务 获取现金收支明细列表接口
 * @returns {*}
 */
export function cash (params) {
  return request({
    url: 'finance/cash/list',
    method: 'get',
    params
  })
}
/**
 * 财务 获取现金收支明细统计接口
 * @returns {*}
 */
export function cashstatistics (params) {
  return request({
    url: 'finance/cash/statistics',
    method: 'get',
    params
  })
}

/**
 * 财务 获取充值收支明细列表接口
 * @returns {*}
 */
export function recharge (params) {
  return request({
    url: 'finance/recharge/list',
    method: 'get',
    params
  })
}

/**
 * 财务 获取发票列表接口
 * @returns {*}
 */
export function getinvoice (params) {
  return request({
    url: 'finance/invoice/list',
    method: 'get',
    params
  })
}
/**
 * 财务 发票管理 获取发票信息接口
 * @returns {*}
 */
export function invoiceget_invoice_info (id,params) {
  return request({
    url: `finance/invoice/get_invoice_info`,
    method: 'get',
    params
  })
}
/**
 * 财务 发票管理 开票接口
 * @returns {*}
 */
export function invoicebilling (id,data) {
  return request({
    url: `finance/invoice/billing/${id}`,
    method: 'post',
    data
  })
}

/**
 * 财务 获取提现列表接口
 * @returns {*}
 */
export function getextract (params) {
  return request({
    url: 'finance/extract/list',
    method: 'get',
    params
  })
}
/**
 * 财务 提现接口
 * @returns {*}
 */
export function withdraw (data) {
  return request({
    url: `finance/extract/withdraw`,
    method: 'post',
    data
  })
}

/**
 * 财务 获取提现统计接口
 * @returns {*}
 */
export function extractcount (params) {
  return request({
    url: 'finance/extract/count',
    method: 'get',
    params
  })
}
/**
 * 财务 提现 通过
 * @returns {*}
 */
export function savepass (id,params) {
  return request({
    url: `finance/extract/pass/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 提现 拒绝
 * @returns {*}
 */
export function savereject (id,data) {
  return request({
    url: `finance/extract/reject/${id}`,
    method: 'post',
    data
  })
}
/**
 * 财务 提现 删除
 * @returns {*}
 */
export function savedelete (id,params) {
  return request({
    url: `finance/extract/delete/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 共享司机提现 获取提现列表接口
 * @returns {*}
 */
export function getdriver_extract (params) {
  return request({
    url: 'finance/driver_extract/list',
    method: 'get',
    params
  })
}
/**
 * 财务 共享司机提现 司机累计提现统计接口
 * @returns {*}
 */
export function getstatistics (params) {
  return request({
    url: 'finance/driver_extract/count',
    method: 'get',
    params
  })
}
/**
 * 财务 共享司机 提现 通过
 * @returns {*}
 */
export function savepasspass (id,params) {
  return request({
    url: `finance/driver_extract/pass/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 共享司机 提现 拒绝
 * @returns {*}
 */
export function refusereject (id,data) {
  return request({
    url: `finance/driver_extract/reject/${id}`,
    method: 'post',
    data
  })
}

/**
 * 财务 共享司机 提现 删除
 * @returns {*}
 */
export function deletedriver_extract (id,params) {
  return request({
    url: `finance/driver_extract/delete/${id}`,
    method: 'get',
    params
  })
}



/**
 * 财务 运力待对账列表接口
 * @returns {*}
 */
export function getdelay_reconciliation (params) {
  return request({
    url: 'finance/capacity_reconciliation/delay_reconciliation',
    method: 'get',
    params
  })
}

/**
 * 财务 运力待对账 发起对账接口
 * @returns {*}
 */
export function initiate (data) {
  return request({
    url: `finance/capacity_reconciliation/initiate`,
    method: 'post',
    data
  })
}

/**
 * 财务 获取服务商
 * @returns {*}
 */
export function facilitatorselect (params) {
  return request({
    url: 'capacity/facilitator/select',
    method: 'get',
    params
  })
}

/**
 * 财务 对账确认操作接口
 * @returns {*}
 */
export function capacity_reconciliationinitiate (id,params) {
  return request({
    url: `finance/capacity_reconciliation/initiate/${id}`,
    method: 'post',
    params
  })
}

/**
 * 财务 运力统计接口
 * @returns {*}
 */
export function capacity_reconciliationreconciliation_statistics (id,params) {
  return request({
    url: `finance/capacity_reconciliation/reconciliation_statistics/${id}`,
    method: 'get',
    params
  })
}


/**
 * 财务 获取待对账订单接口
 * @returns {*}
 */
export function capacity_reconciliation (id,params) {
  return request({
    url: `finance/capacity_reconciliation/details/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 获取详情页面中列表数据
 * @returns {*}
 */
export function getorder_list (id,params) {
  return request({
    url: `finance/capacity_reconciliation/order_list/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 详情 对账订单确认操作
 * @returns {*}
 */
export function capacity_reconciliationaffirm (id,params) {
  return request({
    url: `finance/capacity_reconciliation/affirm/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 详情 对账订单撤销操作
 * @returns {*}
 */
export function capacity_reconciliationrevocation (id,params) {
  return request({
    url: `finance/capacity_reconciliation/revocation/${id}`,
    method: 'get',
    params
  })
}
/**
 * 财务 详情 对账订单不符操作
 * @returns {*}
 */
export function capacity_reconciliationreject (id,data) {
  return request({
    url: `finance/capacity_reconciliation/reject/${id}`,
    method: 'post',
    data
  })
}


/**
 * 财务 运力待开票列表接口
 * @returns {*}
 */
export function capacity_reconciliationdelay_reconciliation (params) {
  return request({
    url: 'finance/capacity_reconciliation/delay_invoice',
    method: 'get',
    params
  })
}


/**
 * 财务 运力待付款列表接口
 * @returns {*}
 */
export function getfukuan (params) {
  return request({
    url: 'finance/capacity_reconciliation/delay_remittance',
    method: 'get',
    params
  })
}

/**
 * 财务 运力待收款列表接口
 * @returns {*}
 */
export function getshoukuan (params) {
  return request({
    url: 'finance/capacity_reconciliation/delay_proceeds',
    method: 'get',
    params
  })
}


/**
 * 财务 获取自动结算订单列表接口
 * @returns {*}
 */
export function autoorder (params) {
  return request({
    url: 'finance/auto/order',
    method: 'get',
    params
  })
}



/**
 * 提现申请 是否配置了支付密码
 * @returns {*}
 */
export function is_pay_password (params) {
  return request({
    url: 'common/is_pay_password',
    method: 'get',
    params
  })
}

/**
 * 提现申请 配置支付密码
 * @returns {*}
 */
export function set_pay_password (data) {
  return request({
    url: `common/set_pay_password`,
    method: 'post',
    data
  })
}

/**
 * 财务 财务大屏 服务收入总额接口
 * @returns {*}
 */
export function service_statistics (type,params) {
  return request({
    url: `finance_screen/service_statistics/${type}`,
    method: 'get',
    params
  })
}

/**
 * 财务 财务大屏 服务支出总额接口
 * @returns {*}
 */
export function account_payments (type,params) {
  return request({
    url: `finance_screen/account_payments/${type}`,
    method: 'get',
    params
  })
}

/**
 * 财务 财务大屏 收支明细统计接口
 * @returns {*}
 */
export function income_detail (type,params) {
  return request({
    url: `finance_screen/income_detail/${type}`,
    method: 'get',
    params
  })
}

/**
 * 财务 财务大屏 收入支出统计接口
 * @returns {*}
 */
export function income_statistics (type,params) {
  return request({
    url: `finance_screen/income_statistics/${type}`,
    method: 'get',
    params
  })
}

/**
 * 财务 财务大屏 支出统计接口
 * @returns {*}
 */
export function expenditure_statistics (type,params) {
  return request({
    url: `finance_screen/expenditure_statistics/${type}`,
    method: 'get',
    params
  })
}



/**
 * 财务 财务统计
 * @returns {*}
 */
export function client_statistics (params) {
  return request({
    url: 'finance/client_statistics',
    method: 'get',
    params
  })
}



/**
 * 财务 员工薪酬发放记录
 * @returns {*}
 */
export function staff_emolument_list (params) {
  return request({
    url: 'finance/staff_emolument_list',
    method: 'get',
    params
  })
}
/**
 * 财务 员工薪酬发放记录详情接口
 * @returns {*}
 */
export function staff_emolument_details (id,params) {
  return request({
    url: `finance/staff_emolument_details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 财务 员工薪酬发放记录审核接口
 * @returns {*}
 */
export function staff_emolument_check (id,data) {
  return request({
    url: `finance/staff_emolument_check/${id}`,
    method: 'get',
    data
  })
}
/**
 * 财务 详情 对账订单不符操作
 * @returns {*}
 */
export function staff_emolument_edit (id,data) {
  return request({
    url: `finance/staff_emolument_edit/${id}`,
    method: 'post',
    data
  })
}


/**
 * 财务 收支明细 明细列表
 * @returns {*}
 */
export function incomelist (params) {
  return request({
    url: `/finance/income/list`,
    method: 'get',
    params
  })
}

/**
 * 财务 详情 在线抽奖记录
 * @returns {*}
 */
export function lottery_record (params) {
  return request({
    url: `/finance/lottery_record`,
    method: 'get',
    params
  })
}


/**
 * 推广记录
 * @returns {*}
 */
export function fwtg_list (params) {
  return request({
    url: `/finance/spread/record`,
    method: 'get',
    params
  })
}

/**
 * 推广记录项目
 * @returns {*}
 */
export function tg_type_list () {
  return request({
    url: `/finance/spread/items`,
    method: 'get',
  })
}

/**
 * //收支明细记录项目
 * @returns {*}
 */
export function incomeitems () {
  return request({
    url: `/finance/income/items`,
    method: 'get',
  })
}


/**
 * 推广人属性下拉列表
 * @returns {*}
 */
export function spreadattribute (params) {
  return request({
    url: `/common/spread/attribute`,
    method: 'get',
    params
  })
}





